import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { tap } from 'rxjs/operators';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse} from '@angular/common/http';
import {AppConfig} from './app.config';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';



@Injectable()
export class AppInterceptor implements HttpInterceptor {
  config;

  constructor(
    appConfig: AppConfig,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.config = appConfig.getConfig();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({url: this.config.baseURLApi + req.url});
// console.log(req);
    const token: string = localStorage.getItem('token');
    if (token) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
    }

    return next.handle(req).pipe(tap(() => {},
      (e: any)=>{
      console.log("ZZZZZ222222222222222222222222222222222");
      if (e instanceof HttpErrorResponse) {
        console.log("AAAAAAAAAAAAAAAAAAA");
        console.log(e);
        console.log("BBBBBBBBBBBB");    
          if (e.status === 401) {
            this.toastr.error("Error !!!!!!!!!!!",e.error.message, {timeOut: 3000});
            //  throw new Error('invalid token');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            this.router.navigate(['/login']);
          }
      }
      return e;
      }
  ));;
  }
}
