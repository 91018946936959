import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';

import { ROUTES } from './app.routes';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { ErrorComponent } from './pages/error/error.component';
import {AppInterceptor} from './app.interceptor';
import {LoginService} from './pages/login/login.service';
import {AppGuard} from './app.guard';
import { NgxFileDragDropModule } from "ngx-file-drag-drop";
import { AgGridModule } from 'ag-grid-angular';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { VideoService } from './main/services/video.service';
import { ActionsCellRendererComponent } from './main/users/actions-cell-renderer.component';



const APP_PROVIDERS = [
  AppConfig,
  LoginService,
  AppGuard,
  VideoService
];

@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    ErrorComponent,
    ActionsCellRendererComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    NgxFileDragDropModule,
    
    BrowserAnimationsModule,
    FormsModule,
    AgGridModule,
    NgxDatatableModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    RouterModule.forRoot(ROUTES, {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    
    // relativeLinkResolution: 'legacy'
})
  ],
  providers: [
    APP_PROVIDERS,
    {
      provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true
    }
  ]
})
export class AppModule {}
