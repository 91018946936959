import {AppConfig} from '../../app.config';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Injectable} from '@angular/core';
import { Observable } from 'rxjs';

const jwt = new JwtHelperService();

@Injectable()
export class VideoService {
  config: any;
  _isFetching: boolean = false;
  _errorMessage: string = '';

  constructor(
    appConfig: AppConfig,
    private http: HttpClient,
    private router: Router,
  ) {
    this.config = appConfig.getConfig();
  }


getUserList(page: number, size:number=10,search: string=""): Observable<any> {
    const uri = `/api/users/getAll`;
    return this.http.get<any>(uri,{params:{page:page,size:size,search: search}});
}
saveUser(id: number,username: string, password: string,email: string,rights: string): Observable<any> {
  const uri = `/api/users/save`;
  return this.http.post<any>(uri,{params:{id:id, username:username,password:password,email: email,rights:rights}});
}

getList(page: number, group:string="", size:number=10,items:Itags[]=[],search: string="",order: string="DESC"): Observable<any> {
    const uri = `/api/videos/getAll?group=`+group;
    return this.http.get<any>(uri,{params:{page:page,size:size,site:group , tags: JSON.stringify(items),search: search, order: order}});
}
removeItem(id: number): Observable<any> {
  const uri = `/api/videos/removeVideo`;
  return this.http.get<any>(uri,{params:{id:id}});
}

public getAutocompleteItems(text: string): Observable<any>  {
  const url = `/api/tagsearch?q=${text}`;
  return this.http.get<any>(url)
      
};
saveData(image: File, filename: string, descr: string, site:string, items:Itags[]=[]): Observable<any> {
  const formData: FormData = new FormData();
  
  formData.append('filez', image);
  formData.append('filename',filename);
  formData.append('descr',descr);
  formData.append('site',site);
  formData.append('items',JSON.stringify(items));
  console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
  console.log(formData);
  return this.http
    .post( '/api/upload', formData,{  reportProgress: true,observe: 'events'}
  );
}


saveTag(tagname: string, tagcolor: string): Observable<any> {
  const formData: FormData = new FormData();
  
  formData.append('tagname',tagname);
  formData.append('tagcolor',tagcolor);
  
  return this.http
    .post( '/api/saveTag', formData
  );
}



  get isFetching() {
    return this._isFetching;
  }

  set isFetching(val: boolean) {
    this._isFetching = val;
  }

  get errorMessage() {
    return this._errorMessage;
  }

  set errorMessage(val: string) {
    this._errorMessage = val;
  }

  isAuthenticated() {
    const token = localStorage.getItem('token');
    let data = null;

    // We check if app runs with backend mode
    if (!this.config.isBackend && token) return true;
    if (!token) return;
    const date = new Date().getTime() / 1000;
    try {
    data = jwt.decodeToken(token);
    } catch(e) {
      this.router.navigate(['/login']);
    }
    if (!data) return;
    return date < data.exp;
  }

  loginUser(creds) {
    // We check if app runs with backend mode
    if (!this.config.isBackend) {
      this.receiveToken('token');
    } else {
      this.requestLogin();
      if (creds.social) {
        // tslint:disable-next-line
        window.location.href = this.config.baseURLApi + '/user/signin/' + creds.social + (process.env.NODE_ENV === 'production' ? '?app=sing-app/angular' : '');
      } else if (creds.email.length > 0 && creds.password.length > 0) {
        this.http.post('/user/signin/local', creds).subscribe((res: any) => {
          const token = res.token;
          this.receiveToken(token);
        }, err => {
          this.loginError('Something was wrong. Try again');
        });

      } else {
        this.loginError('Something was wrong. Try again');
      }
    }
  }

  receiveToken(token) {
    let user: any = {};
    // We check if app runs with backend mode
    if (this.config.isBackend) {
      user = jwt.decodeToken(token).user;
      delete user.id;
    } else {
      user = {
        email: this.config.auth.email
      };
    }

    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    this.receiveLogin();
  }

  logoutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    this.router.navigate(['/login']);
  }
  public canDelete(): boolean {
    let token=localStorage.getItem('token');
    let a = jwt.decodeToken(token);
    let rights = a.data.rights.split(",");
    if (rights.some(x=> x == "delete"))return true;
    return false

  }
  public canUpload(): boolean {
    let token=localStorage.getItem('token');
    let a = jwt.decodeToken(token);
    let rights = a.data.rights.split(",");
    if (rights.some(x=> x == "write"))return true;
    return false

  }
  public isAdmin(): boolean {
    let token=localStorage.getItem('token');
    let a = jwt.decodeToken(token);
    let rights = a.data.rights.split(",");
    if (rights.some(x=> x == "admin"))return true;
    return false

  }
loginInfo(){
  let token=localStorage.getItem('token');
  let a = jwt.decodeToken(token);
  let rights = a.data.rights.split(",");
  if (rights.some(x=> x == "delete")){
    console.log("DELETE");
  }
  console.log(rights,a.data,token);

}
  loginError(payload) {
    this.isFetching = false;
    this.errorMessage = payload;
  }

  receiveLogin() {
    this.isFetching = false;
    this.errorMessage = '';
    this.router.navigate(['/app/main/videos']);
  }

  requestLogin() {
    this.isFetching = true;
  }
}


export interface Itags {
  color: string;
  value: string;
  display: string;
}